import mainServices from '@/services/main'
export default {
  data () {
    return {
      allCities: [],
      allCountries: []
    }
  },
  methods: {
    getAllCountries () {
      mainServices.getAllCities().then(res => {
        this.allCities = res.data.data
      })
    },
    getAllCities () {
      mainServices.getAllContries().then(res => {
        this.allCountries = res.data.data
      })
    }
  },
  created () {
    this.getAllCountries()
    this.getAllCities()
  }
}
